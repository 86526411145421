const domain = window.location.hostname;
const isStage = domain.includes("stage") || domain.includes("localhost");
const location = 
window.location.pathname === "/quiz" ? 'quiz' :
window.location.pathname === "/questionnaire" ? 'questionnaire' :
window.location.pathname === "/voter-guide" ? 'voter-guide' : 'landing-page'

const gtmId = "GTM-KWJSL9L";
// console.log("GTM ID: ", gtmId);

// Load the Google Analytics script
const script = document.createElement("script");
script.async = true;
script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", gtmId);

// Might go with this path instead:

// https://support.google.com/analytics/answer/10269537?hl=en
// - register the website url  through google anayltics as opposed to installing a script

const menuItems = document.getElementsByClassName("nav-item") //header menu
for (const nav of menuItems) {
  nav.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_Navigation',
      'dl_clickedLink': nav.children[0].innerHTML,
      'dl_destinationURL': nav.children[0].href,
      'dl_location': 'menu',
    })
  })
};

const voterResources = document.getElementsByClassName("wrapper-box-content");
for (const link of voterResources) {
  link.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_Navigation',
      'dl_clickedLink': link.children[0].innerHTML,
      'dl_destinationURL': link.getElementsByTagName("a")[0].href,
      'dl_location': 'voter-resources',
    })
  })
}

const cstLogos = document.getElementsByClassName("cst logo-wrapper");
const wbezLogos = document.getElementsByClassName("wbez logo-wrapper");

for (const logo of cstLogos) {
  logo.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_Navigation',
      'dl_clickedLink': 'Chicago Sun-Times Logo',
      'dl_destinationURL': logo.parentElement.href,
      'dl_location': logo.id.includes("header") ? 'header' : 'footer',
    })
  })
}

for (const logo of wbezLogos) {
  logo.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_Navigation',
      'dl_clickedLink': 'WBEZ Logo',
      'dl_destinationURL': logo.parentElement.href,
      'dl_location': logo.id.includes("header") ? 'header' : 'footer',
    })
  })
}


const donateBtn = document.getElementsByClassName("donate-btn")[0]; //donate button
donateBtn.addEventListener("click", () => {
  window.dataLayer.push({
    'event': 'e_Donate',
    'dl_location': location
  })
})

const buttons = document.getElementsByClassName("link-out-btn"); //quiz and voter-guide
for (const btn of buttons) {
  btn.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_ButtonClick',
      'dl_buttonTitle': btn.id,
      'dl_destinationURL': btn.children[0].href,
    })
  })
};

const stories = document.getElementsByClassName("story"); //related stories
for (const story of stories) {
  story.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_RelatedStory',
      'dl_storyTitle': story.getElementsByClassName("news-title")[0].innerHTML,
      'dl_destinationURL': story.children[0].href,
      'dl_storyPosition': story.id,
      'dl_cardLocation': location
    })
  });
};

const pplsAgenda = document.getElementsByClassName("cta-asset")[0]; //people's agenda CTA
if (pplsAgenda !== undefined) {
  pplsAgenda.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_CTAEngagement',
      'dl_CTAEngagementType': 'event-registration',
      'dl_CTAType': 'submit',
      'dl_CTALocation': location,
      'dl_CTAName': 'peoples-agenda'
    })
  })
}

const chatterLinks = document.getElementsByClassName("chatter");
for (const cL of chatterLinks) {
  cL.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_Navigation',
      'dl_clickedLink': cL.innerHTML,
      'dl_destinationURL': cL.href,
      'dl_location': cL.id.includes("quest") ? 'questionnaire-chatter' : 'quiz-chatter',
    })
  })
}

const start = document.getElementsByClassName("start-quiz")[0];
if (start !== undefined) {
  start.addEventListener("click", () => {
    window.dataLayer.push({
      'event': 'e_StartQuiz',
    })
  })
}
